import { Button } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Zoom from "@material-ui/core/Zoom";
import DateAdapter from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { default as Axios, default as axios } from "axios";
import { SnackbarProvider } from "notistack";
import numeral from "numeral";
import "numeral/locales/pt-br";
import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { authLogin, authLogout } from "./actions";
import "./config/api";
import RotasLogado from "./config/rotasLogado";
import RotasVisitante from "./config/rotasVisitante";
import { tema } from "./config/themas";
import AuthContext from "./context/auth-context";
import { store } from "./store";

numeral.locale("pt-br");

const App = (props) => {
  const [usuarioLogado, setUsuarioLogado] = useState();
  const theme = "claro";

  if (store.getState().authReducer.token && !usuarioLogado) {
    Axios.defaults.headers.common = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getState().authReducer.token,
    };

    setUsuarioLogado(store.getState().authReducer);
  }
  useEffect(() => {
    Axios.defaults.headers.common = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getState().authReducer.token,
    };
  }, []);

  const login = async (loginData) => {
    axios.defaults.headers.common = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + loginData.token,
    };
    setUsuarioLogado(loginData);
    store.dispatch(authLogin(loginData));
  };

  const logout = () => {
    setUsuarioLogado(null);
    store.dispatch(authLogout());
  };

  // logout();

  const muiTheme = createTheme(tema.claro);
  const notistackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
      }}
    >
      <ThemeProvider theme={muiTheme}>
        <CssBaseline />
        <BrowserRouter>
          <SnackbarProvider
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            TransitionComponent={Zoom}
            maxSnack={10}
            preventDuplicate
            ref={notistackRef}
            action={(key) => (
              <Button onClick={onClickDismiss(key)}>Fechar</Button>
            )}
          >
            <LocalizationProvider dateAdapter={DateAdapter}>
              <div className={"tema-claro"}>
                {usuarioLogado && usuarioLogado.token !== "" && (
                  <RotasLogado
                    login={login}
                    logout={logout}
                    theme={theme}
                    usuarioLogado={usuarioLogado}
                  />
                )}
                {!usuarioLogado && (
                  <RotasVisitante login={login} usuarioLogado={usuarioLogado} />
                )}
              </div>
            </LocalizationProvider>
          </SnackbarProvider>
        </BrowserRouter>
      </ThemeProvider>
    </AuthContext.Provider>
  );
};
export default App;
