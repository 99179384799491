import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Topo from "../componentes/layout/Topo";
import Login from "../paginas/Login";
import PedidoRetornoPagSeguro from "../paginas/PedidoRetornoPagSeguro";
import SolicitarAcesso from "../paginas/SolicitarAcesso";

const RotasVisitante = (props) => {
  return (
    <React.Fragment>
      <Topo />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/solicitar-acesso" element={<SolicitarAcesso />} />
        <Route path="*" element={<Navigate replace to="/login" />} />
        <Route
          path="/pedidos/retorno/:status_pagamento"
          element={<PedidoRetornoPagSeguro />}
        />
      </Routes>
    </React.Fragment>
  );
};

export default RotasVisitante;
