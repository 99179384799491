import { AUTH_LOGIN, AUTH_LOGOUT } from "./actionTypes";

export const authLogin = (loginData) => ({
  type: AUTH_LOGIN,
  id: loginData.id,
  nome: loginData.nome,
  token: loginData.token,
  grupo_nome: loginData.grupo_nome,
});

export const authLogout = () => ({
  type: AUTH_LOGOUT,
  id: null,
  nome: null,
  token: null,
  grupo_nome: null,
});
