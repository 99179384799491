import { Button, Grid, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Painel from "../componentes/painel/Painel";
import { UsuarioPromisor } from "../promisors";

const SolicitarAcesso = (props) => {
  const [dadosFormulario, setDadosFormulario] = useState({});
  const [errosForm, setErrosFormulario] = useState();
  const [carregando, setCarregando] = useState(false);
  const [cadastroEnviado, setCadastroEnviado] = useState(false);

  const solicitarAcesso = async () => {
    if (carregando) return;
    setCarregando(true);

    UsuarioPromisor.solicitarAcesso(dadosFormulario)
      .then((resuldato) => {
        if (resuldato.sucesso) {
          setCadastroEnviado(true);
        }
      })
      .catch((error) => {
        setCarregando(false);

        if (
          error.response &&
          error.response.data &&
          error.response.data.mensagem
        ) {
          setErrosFormulario(error.response.data.mensagem);
        }
      });
  };

  return (
    <Grid container spacing={0} justifyContent="center">
      <Grid item xs={11}>
        {cadastroEnviado && (
          <Painel titulo="Solicitar acesso" className={"mt-3"}>
            <Grid container spacing={2}>
              <Grid item xs={11}>
                <Typography variant="h6">
                  Cadastro enviado com sucesso
                </Typography>
                <Typography variant="subtitle1">
                  Em breve você receberá um email com os dados de acesso.
                </Typography>
                <Link to="/">
                  <Button className="mt-3" variant="contained">
                    Voltar
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Painel>
        )}
        {!cadastroEnviado && (
          <Painel titulo="Solicitar acesso" className={"mt-3"}>
            <Grid container spacing={2}>
              <Grid item xs={11}>
                <Typography variant="h6">Dados de acesso</Typography>
              </Grid>
              <Grid item xs={11} md={4} lg={4}>
                <TextField
                  margin="dense"
                  label="Nome"
                  autoComplete="off"
                  fullWidth
                  autoFocus
                  error={
                    errosForm &&
                    errosForm.nomeAcesso &&
                    errosForm.nomeAcesso.length > 0
                  }
                  helperText={
                    errosForm &&
                    errosForm.nomeAcesso &&
                    errosForm.nomeAcesso.length > 0 &&
                    errosForm.nomeAcesso[0]
                  }
                  value={
                    dadosFormulario && dadosFormulario.nomeAcesso
                      ? dadosFormulario.nomeAcesso
                      : ""
                  }
                  onChange={(e) =>
                    setDadosFormulario({
                      ...dadosFormulario,
                      nomeAcesso: e.target.value,
                    })
                  }
                  className={"mb-3"}
                />
              </Grid>
              <Grid item xs={11} md={4} lg={4}>
                <TextField
                  margin="dense"
                  label="E-mail"
                  autoComplete="off"
                  fullWidth
                  autoFocus
                  error={
                    errosForm &&
                    errosForm.emailAcesso &&
                    errosForm.emailAcesso.length > 0
                  }
                  helperText={
                    errosForm &&
                    errosForm.emailAcesso &&
                    errosForm.emailAcesso.length > 0 &&
                    errosForm.emailAcesso[0]
                  }
                  value={
                    dadosFormulario && dadosFormulario.emailAcesso
                      ? dadosFormulario.emailAcesso
                      : ""
                  }
                  onChange={(e) =>
                    setDadosFormulario({
                      ...dadosFormulario,
                      emailAcesso: e.target.value,
                    })
                  }
                  className={"mb-3"}
                />
              </Grid>
            </Grid>

            <Button
              variant="contained"
              color="primary"
              onClick={solicitarAcesso}
              disabled={carregando}
              className="mt-1"
            >
              {carregando ? "Aguarde..." : "Solicitar acesso"}
            </Button>
          </Painel>
        )}
      </Grid>
    </Grid>
  );
};

export default SolicitarAcesso;
