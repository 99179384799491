import DeleteIcon from "@mui/icons-material/Delete";
import { Button, ButtonGroup, Input } from "@mui/material";
import React from "react";
const AdicionaRemoveQuantidade = (props) => {
  return (
    <ButtonGroup
      size="small"
      variant="text"
      className={props.className ? props.className : ""}
    >
      {props.quantidade > 1 && (
        <Button
          disabled={props.quantidade <= 1}
          variant="contained"
          color="primary"
          size="small"
          onClick={() => props.removeQuantidade(props.produto_id)}
        >
          -
        </Button>
      )}

      {props.quantidade === 1 && (
        <Button
          variant="contained"
          color="primary"
          className="btn-danger"
          size="small"
          onClick={() => props.removerProduto(props.produto_id)}
        >
          <DeleteIcon size="small" />
        </Button>
      )}
      <Input
        size="small"
        className="adicionaRemoveQuantidade-input"
        value={props.quantidade}
      />
      <Button
        size="small"
        variant="contained"
        onClick={() => props.adicionaQuantidade(props.produto_id)}
      >
        +
      </Button>
    </ButtonGroup>
  );
};

export default AdicionaRemoveQuantidade;
