import { Button, Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import PedidoPromisor from "../promisors/PedidoPromisor";
const PedidoRetornoPagSeguro = (props) => {
  const [searchParams] = useSearchParams();
  const [pedido, setPedido] = React.useState();

  useEffect(() => {
    const preferencia = searchParams.get("preference_id");
    const status = searchParams.get("status");
    const dados = {
      preferencia,
      status,
    };
    PedidoPromisor.atualizaStatusPagamento(dados).then((response) => {
      if (response.sucesso) {
        setPedido(response.dados);
      } else {
        alert(response.mensagem);
      }
    });
  }, []);

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          {!pedido && (
            <div className="m-3 mt-0">
              <Typography variant="subtitle1" className={"mb-3"}>
                Aguarde...
              </Typography>
            </div>
          )}
          {pedido && pedido.status_pagamento === "approved" && (
            <div className="m-3 mt-0">
              <Typography variant="h5" className={"mb-3 text-center"}>
                Seu pagamento foi aprovado.
              </Typography>
            </div>
          )}
          {pedido && pedido.status_pagamento === "in_process" && (
            <div className="m-3 mt-0">
              <Typography variant="h5" className={"mb-3 text-center"}>
                Seu pagamento esta pendente.
              </Typography>
              <Typography variant="subtitle1" className={"mb-3 text-center"}>
                Logo que ele for aprovado entraremos em contato para validar o
                seu pedido.
              </Typography>
              <Typography variant="subtitle1" className={"mb-3 text-center"}>
                Você pode acompanhar o andamento da liberação pelo seu email,
                com as mensagens vindas do <b>Mercado Pago</b>
              </Typography>
              <div className="text-center">
                <Link to="/">
                  <Button className="mt-3" color="primary" variant="contained">
                    Voltar
                  </Button>
                </Link>
              </div>
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default PedidoRetornoPagSeguro;
