import { Divider, Typography } from "@material-ui/core";
import React from "react";
import { tema } from "../../config/themas";
import { store } from "../../store";

const PainelTopo = (props) => {
  const temaAtual = store.getState().temaReducer
    ? store.getState().temaReducer.tema
    : tema.claro;

  const estiloCores = {
    backgroundColor: temaAtual.palette.primary.main,
    color: temaAtual.palette.primary.contrastText,
  };

  return (
    <>
      <div className="header" style={estiloCores}>
        <div className="titulo">
          <Typography className="m-0 " color="textSecondary">
            {props.titulo}
          </Typography>
        </div>
      </div>
      <Divider />
    </>
  );
};

export default PainelTopo;
