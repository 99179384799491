import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Painel from "../componentes/painel/Painel";
import { store } from "../store";

const Inicial = (props) => {
  return (
    <Grid spacing={3} container justifyContent="center">
      <Grid item xs={11} md={6} lg={4}>
        <Painel titulo="Geração de pedidos MetaLife Pilates" className={"mt-3"}>
          <Typography variant="h5" component="h3" className="mb-3">
            Bem vindo (a),
          </Typography>
          <Typography variant="body1">
            Seja muito bem vindo (a) à Metalife Pilates, líder na fabricação de
            equipamentos de Pilates no Brasil e América Latina. Você que é nosso
            parceiro oficial tem um ambiente reservado a você para facilitar a
            escolha dos equipamentos e acessórios e uma condição de pagamento
            dedicada à parceria Metalife Pilates e{" "}
            {store.getState().authReducer.grupo_nome}. Preste bastante atenção a
            todos os passos do processo de compra e caso tenha qualquer dúvida
            ligue para o telefone 0800 056 2467. <br />
            <br />
            Ficamos muito felizes em tê-los na família Metalife!
          </Typography>
        </Painel>
      </Grid>
      <Grid item xs={11} md={4} lg={3}>
        <Painel titulo="Criar pedido" className={"mt-3 text-center"}>
          <Typography variant="h6" component="h3">
            Para criar um novo pedido,
            <br /> clique no botão abaixo
          </Typography>
          <Link to="/pedidos/criar">
            <Button className="mt-3" variant="contained">
              criar pedido
            </Button>
          </Link>
        </Painel>
      </Grid>
    </Grid>
  );
};

export default Inicial;
