import moment from "moment";
import { default as React } from "react";
import ReactDOM from "react-dom";
import { connect, Provider } from "react-redux";
import { bindActionCreators } from "redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { authReducer } from "./reducers/authReducer";
import reportWebVitals from "./reportWebVitals";
import { persistor, store } from "./store";
import "./styles/css/index.css";
moment.locale("pt-br");

const Index = (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </PersistGate>
  </Provider>
);

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ authReducer }, dispatch);

export default connect(mapDispatchToProps)(App);

ReactDOM.render(Index, document.getElementById("root"));

reportWebVitals();
