import Axios from "axios";

const PedidoPromisor = {
  criar: (dados) => {
    return new Promise((resolve, reject) => {
      Axios.post("/api/venda-direta/pedido/criar", dados)
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  checarEstado: (dados) => {
    return new Promise((resolve, reject) => {
      Axios.post("/api/venda-direta/pedido/verifica-estado-entrega", dados)
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  atualizaStatusPagamento: (dados) => {
    return new Promise((resolve, reject) => {
      Axios.post("/api/venda-direta/pedido/atualiza-status-pagamento", dados)
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
export default PedidoPromisor;
