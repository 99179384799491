import Axios from "axios";

const MercadoPagoPromisor = {
  criarPreferencia: (produtos, cliente, tipoDesconto, desconto) => {
    let items = [];
    produtos.forEach((produto) => {
      items.push({
        title: produto.nome,
        quantity: produto.quantidade,
        currency_id: "BRL",
        unit_price: -(
          (produto.valor_tabela * desconto) / 100 -
          produto.valor_tabela
        ),
      });
    });
    const params = {
      items: items,
      payer: {
        name: cliente.nome,
        phone: {
          area_code: cliente.ddd,
          number: cliente.telefone_sem_ddd,
        },
        email: cliente.email,
        identification: {
          number: cliente.documento,
          type: cliente.documento.length === 11 ? "CPF" : "CNPJ",
        },
        address: {
          street_name: cliente.endereco,
          zip_code: cliente.cep,
        },
      },
      payment_methods: {
        installments: tipoDesconto === "avista" ? 1 : 12,
        excluded_payment_methods: [{}],
        excluded_payment_types: [{}],
      },
      shipments: {
        free_methods: [{}],
        receiver_address: {},
      },
      back_urls: {
        success: "http://vd.metalifepilates.com.br/pedidos/retorno/success",
        pending: "http://vd.metalifepilates.com.br/pedidos/retorno/pending",
        failure: "http://vd.metalifepilates.com.br/pedidos/retorno/failure",
      },
      auto_return: "approved",
      differential_pricing: {},
    };

    return new Promise((resolve, reject) => {
      Axios.post("https://api.mercadopago.com/checkout/preferences", params, {
        headers: {
          Authorization: `Bearer APP_USR-4788976010689487-053015-78556a4377dcd8738eaeb5e379d2283c-24265109`,
        },
      })
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          console.log("Mercadopago erro", e);
          reject(e);
        });
    });
  },
};
export default MercadoPagoPromisor;
