import Axios from "axios";

const ProdutoPromisor = {
  lista: (params) => {
    return new Promise((resolve, reject) => {
      Axios.get(
        "/api/v1/orcamento/grupos-produtos/lista?astec=0&token=ef5e5fc652c78ea3bb4d97fca285fe998329a260",
        params
      )
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
export default ProdutoPromisor;
