const escuro = {
    palette: {
        type: "dark",
        primary: {
            light: "##00b9ca",
            main: "#008591",
            dark: "#00717b",
            contrastText: "#fff",
        },
        secondary: {
            light: "#e25e15",
            main: "#B4470A",
            dark: "#9c3e0a",
            contrastText: "#000",
        },
        background: {
            default: "#252429",
            paper: "#35343c",
        },
    },
};

const claro = {
    palette: {
        type: "light",
        primary: {
            light: "##00b9ca",
            main: "#008591",
            dark: "#00717b",
            contrastText: "#fff",
        },
        secondary: {
            light: "#e25e15",
            main: "#B4470A",
            dark: "#9c3e0a",
            contrastText: "#000",
        },
        background: {
            default: "#f7f7f7",
            paper: "#fff",
        },
    },
};

export const tema = {
    escuro,
    claro,
};
