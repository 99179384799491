import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Topo from "../componentes/layout/Topo";
import Inicial from "../paginas/Inicial";
import PedidoCriar from "../paginas/PedidoCriar";
import PedidoRetornoPagSeguro from "../paginas/PedidoRetornoPagSeguro";

const RotasLogado = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (props.usuarioLogado && window.location.pathname === "/login") {
      navigate("/");
    }
  }, [props.usuarioLogado]);

  return (
    <React.Fragment>
      <Topo usuarioLogado={props.usuarioLogado} logout={props.logout} />
      <div className="espacamentoTopo"></div>
      <Routes>
        <Route path="/" element={<Inicial />} />
        <Route
          path="/pedidos/criar"
          element={<PedidoCriar usuarioLogado={props.usuarioLogado} />}
        />
        <Route
          path="/pedidos/retorno/:status_pagamento"
          element={<PedidoRetornoPagSeguro />}
        />
      </Routes>
    </React.Fragment>
  );
};

export default RotasLogado;
