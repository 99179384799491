import { Button } from "@material-ui/core";
import React from "react";

const Topo = (props) => {
  return (
    <div id="topo" className=" d-flex">
      <div className=" flex-grow-1">
        <div className="logo">
          <img src="/logo.png" />
          <div className="nomeAplicacao">Venda direta</div>
        </div>
      </div>
      <div className="p-1">
        {props.usuarioLogado && (
          <Button
            variant="contained"
            color="default"
            size="small"
            onClick={() => props.logout()}
          >
            Sair
          </Button>
        )}
      </div>
    </div>
  );
};

export default Topo;
