import { AUTH_LOGIN, AUTH_LOGOUT } from "../actions/actionTypes";

const initialState = {
  id: null,
  nome: null,
  token: null,
  grupo_nome: null,
};
export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_LOGIN:
      return {
        ...state,
        id: action.id,
        nome: action.nome,
        token: action.token,
        grupo_nome: action.grupo_nome,
      };
    case AUTH_LOGOUT:
      return initialState;

    default:
      return state;
  }
};
