import Axios from "axios";

const UsuarioPromisor = {
  solicitarAcesso: (params) => {
    return new Promise((resolve, reject) => {
      Axios.post("/api/venda-direta/usuario/solicitar-acesso", params)
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  logar: (params) => {
    return new Promise((resolve, reject) => {
      Axios.post("/api/venda-direta/usuario/logar", params)
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  pegarDescontos: (params) => {
    return new Promise((resolve, reject) => {
      Axios.post("/api/venda-direta/usuario/descontos?id=" + params)
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
export default UsuarioPromisor;
