import Axios from "axios";

const ClientePromisor = {
  dadosPorId: (id) => {
    return new Promise((resolve, reject) => {
      Axios.post("/api/venda-direta/cliente/dados-por-id/" + id)
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  atualizar: (dados) => {
    return new Promise((resolve, reject) => {
      Axios.post("/api/venda-direta/cliente/atualizarV2", dados)
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          console.log("e", e, e.response);
          reject(e);
        });
    });
  },
};
export default ClientePromisor;
