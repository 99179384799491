import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { ListItemButton, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Painel from "../componentes/painel/Painel";
import AdicionaRemoveQuantidade from "../componentes/uteis/AdicionaRemoveQuantidade";
import ClientePromisor from "../promisors/ClientePromisor";
import MercadoPagoPromisor from "../promisors/MercadoPagoPromisor";
import PedidoPromisor from "../promisors/PedidoPromisor";
import ProdutoPromisor from "../promisors/ProdutoPromisor";
import UsuarioPromisor from "../promisors/UsuarioPromisor";

const clienteErrosInit = {
  nome: null,
  documento: null,
  email: null,
  ddd: null,
  telefone_sem_ddd: null,

  pais_id: null,
  cep: null,
  endereco: null,
  estado: null,
  municipio: null,
  bairro: null,

  cep_entrega: null,
  endereco_entrega: null,
  estado_entrega: null,
  municipio_entrega: null,
  bairro_entrega: null,
};

const PedidoCriar = (props) => {
  const [pedido, setPedido] = useState();
  const [produtos, setProdutos] = useState();
  const [urlPagamento, setUrlPagamento] = useState();
  const [preferenciaId, setPreferenciaId] = useState();
  const [produtosInseridos, setProdutosInseridos] = useState([]);
  const [buscar, setBuscar] = useState("");
  const [etapaAtual, setEtapaAtual] = useState(0);
  const [cliente, setCliente] = useState({
    nome: null,
    cpf: null,
    cnpj: null,
    rg: null,
    email: null,
    telefone: null,
    genero: "E",
    tipo_consumidor: "F",
    inscricao_estadual: null,
    inscricao_municipal: null,
    contribuinte_estadual: "0",

    pais_id: "01058",
    cep: null,
    endereco: null,
    complemento: null,
    estado: null,
    municipio: null,
    bairro: null,

    cep_entrega: null,
    endereco_entrega: null,
    complemento_entrega: null,
    estado_entrega: null,
    municipio_entrega: null,
    bairro_entrega: null,
  });

  const [clienteErros, setClienteErros] = useState(clienteErrosInit);
  const [pagamento, setPagamento] = useState();
  const [dadosPedidoGerado, setDadosPedidoGerado] = useState();
  const [carregando, setCarregando] = useState(false);
  const [alterouDadosCliente, setAlterouDadosCliente] = useState(false);
  const [descontoAVista, setDescontoAVista] = useState();
  const [descontoAPrazo, setDescontoAPrazo] = useState();
  const [tipoDesconto, setTipoDesconto] = useState("avista");
  // if (pagamento) console.log("pagamento", pagamento);
  // if (produtosInseridos) console.log("produtosInseridos", produtosInseridos);
  // if (cliente) console.log("cliente", cliente);

  useEffect(() => {
    // ClientePromisor.dadosPorId(props.usuarioLogado.cliente_id).then(
    //   (resposta) => {
    //     if (resposta.sucesso) {
    //       setCliente(resposta.cliente);
    //     } else {
    //       alert("Erro ao buscar cliente");
    //     }
    //   }
    // );
    UsuarioPromisor.pegarDescontos(props.usuarioLogado.id).then((resposta) => {
      if (resposta.sucesso) {
        setDescontoAVista(resposta.desconto_a_vista);
        setDescontoAPrazo(resposta.desconto_a_prazo);
      } else {
        setDescontoAVista(0);
        setDescontoAPrazo(0);
        alert("Erro ao buscar descontos");
      }
    });
  }, []);

  useEffect(() => {
    ProdutoPromisor.lista({ params: { buscar: buscar } }).then((produtos) => {
      setProdutos(produtos.produtos);
    });
  }, [buscar]);

  const insereProduto = (produto) => {
    let inserir = true;

    produtosInseridos.forEach((produtoInserido) => {
      if (produtoInserido.id === produto.id) {
        inserir = false;
      }
    });

    if (inserir)
      setProdutosInseridos([
        ...produtosInseridos,
        { ...produto, quantidade: 1 },
      ]);
  };

  const removerProduto = (produto_id) => {
    let novo = [];

    produtosInseridos.forEach((produtoInserido) => {
      if (produtoInserido.id !== produto_id) {
        novo.push(produtoInserido);
      }
    });

    setProdutosInseridos(novo);
  };

  const totalSemDesconto = () => {
    let total = 0;
    produtosInseridos.forEach((produto) => {
      total += produto.valor_tabela * produto.quantidade;
    });
    return total;
  };

  const filtrarProdutos = (valor) => {
    setBuscar(valor);
  };

  const adicionaQuantidade = (produto_id) => {
    const dados = [];

    produtosInseridos.forEach((op) => {
      if (op.id !== produto_id) {
        dados.push(op);
      } else {
        const d = {
          id: op.id,
          nome: op.nome,
          categoria: op.categoria,
          quantidade: op.quantidade + 1,
          valor_tabela: op.valor_tabela,
        };

        dados.push(d);
      }
    });
    setProdutosInseridos(dados);
  };

  const removeQuantidade = (produto_id) => {
    const dados = [];

    produtosInseridos.forEach((op) => {
      if (op.id !== produto_id) {
        dados.push(op);
      } else {
        const d = {
          id: op.id,
          nome: op.nome,
          categoria: op.categoria,
          quantidade: op.quantidade - 1,
          valor_tabela: op.valor_tabela,
        };

        if (d.quantidade >= 1) {
          dados.push(d);
        } else {
          dados.push(op);
        }
      }
    });
    setProdutosInseridos(dados);
  };

  const valorFinal = (tipo) => {
    if (tipo === "avista") return valorDescontoAVista();
    if (tipo === "aprazo") return valorDescontoAPrazo();
  };

  const valorDescontoAVista = () => {
    return -((totalSemDesconto() * descontoAVista) / 100 - totalSemDesconto());
  };

  const valorDescontoAPrazo = () => {
    return -((totalSemDesconto() * descontoAPrazo) / 100 - totalSemDesconto());
  };

  const valorParcela = (parcela) => {
    return (
      -((totalSemDesconto() * descontoAPrazo) / 100 - totalSemDesconto()) /
      parcela
    );
  };

  const selecionaFormaPagamento = (forma) => {
    setPagamento(forma);
    setEtapaAtual(etapaAtual + 1);
  };

  const voltarParaProdutos = () => {
    setPagamento();
    setEtapaAtual(0);
  };

  const voltarParaCliente = () => {
    setEtapaAtual(1);
  };

  const atualizaDadosCliente = (nome, valor) => {
    setAlterouDadosCliente(true);
    setCliente({
      ...cliente,
      [nome]: valor,
    });
  };
  const salvarDadosCliente = () => {
    setCarregando(true);
    var erros = 0;

    var errosDict = clienteErrosInit;

    for (const [key, value] of Object.entries(errosDict)) {
      if (!cliente[key] || cliente[key] === "") {
        errosDict = { ...errosDict, [key]: "Campo obrigatório" };
        erros++;
      }
    }

    setClienteErros(errosDict);

    if (erros !== 0) {
      setCarregando(false);
      return;
    }

    PedidoPromisor.checarEstado({
      estado_entrega: cliente.estado,
      usuario_id: props.usuarioLogado.id,
    }).then((resposta) => {
      if (resposta.sucesso) {
        ClientePromisor.atualizar(cliente).then((resposta) => {
          if (resposta.sucesso) {
            gerarPreferencia();
            // gerarPedido("11111");
          } else {
            alert("Erro ao atualizar cliente");
          }
        });
      } else {
        alert(resposta.mensagem);
        setCarregando(true);
      }
    });
  };

  const gerarPreferencia = () => {
    MercadoPagoPromisor.criarPreferencia(
      produtosInseridos,
      cliente,
      pagamento.tipo,
      pagamento.desconto
    ).then((resposta) => {
      console.log(resposta);
      setUrlPagamento(resposta.init_point);
      setPreferenciaId(resposta.id);
      gerarPedido(resposta.id);
    });
  };

  const gerarPedido = (preferencia_id) => {
    const pedido = {
      cliente: cliente,
      forma_pagamento: pagamento,
      produtos: produtosInseridos,
      usuarioLogado: props.usuarioLogado,
      preferenciaId: preferencia_id,
    };

    setCarregando(true);
    PedidoPromisor.criar(pedido).then((resposta) => {
      if (resposta.sucesso) {
        setPedido(resposta.dados);
        setEtapaAtual(2);
        setCarregando(false);
      } else {
        alert("Erro ao salvar dados do pedido");
      }
      setCarregando(false);
    });
  };

  const BotaoPagamento = () => {
    return (
      <Stack spacing={2} direction="row">
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            setaParcelaSelecionada({
              tipo: "avista",
              nome: "A vista",
              valor_com_desconto: valorFinal("avista"),
              valor_parcela: valorFinal("avista"),
              parcelas: 1,
              valor_sem_desconto: totalSemDesconto(),
              desconto: descontoAVista,
            })
          }
        >
          {valorDescontoAVista().toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}{" "}
          á vista
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            setaParcelaSelecionada({
              tipo: "aprazo",
              nome: "A prazo",
              valor_com_desconto: valorFinal("aprazo"),
              valor_sem_desconto: totalSemDesconto(),
              desconto: descontoAPrazo,
            })
          }
        >
          {valorDescontoAPrazo().toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}{" "}
          a prazo
        </Button>
      </Stack>
    );
  };

  const setaParcelaSelecionada = (parcela) => {
    selecionaFormaPagamento(parcela);
  };

  const copiarEndereco = () => {
    setCliente({
      ...cliente,
      cep_entrega: cliente.cep,
      endereco_entrega: cliente.endereco,
      complemento_entrega: cliente.complemento,
      estado_entrega: cliente.estado,
      municipio_entrega: cliente.municipio,
      bairro_entrega: cliente.bairro,
    });
  };

  return (
    <div className={"h-100"}>
      <Grid container spacing={0}>
        {etapaAtual === 0 && (
          <>
            <Grid item xs={12} md={6} lg={6}>
              {/* Lista de produtos */}
              <Painel titulo="Lista de produtos" className={"m-3"}>
                <div>
                  {!produtos && <LinearProgress />}
                  {produtos && (
                    <React.Fragment>
                      <TextField
                        fullWidth
                        placeholder="Buscar..."
                        className={"mb-3"}
                        onChange={(e) => {
                          filtrarProdutos(e.target.value);
                        }}
                      />
                      <List
                        component="nav"
                        aria-label="main mailbox folders"
                        style={{
                          maxHeight: 400,
                          minHeight: 400,
                          overflow: "auto",
                        }}
                      >
                        {produtos.map((produto, i) => {
                          return (
                            <ListItemButton
                              key={produto.id}
                              divider
                              onClick={() => insereProduto(produto)}
                            >
                              <ListItemText
                                primary={produto.nome}
                                secondary={
                                  produto.valor_tabela.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  }) +
                                  " | " +
                                  produto.categoria
                                }
                              />
                            </ListItemButton>
                          );
                        })}
                      </List>
                    </React.Fragment>
                  )}
                </div>
              </Painel>
              <div className="d-flex m-3 mt-6 j-between">
                <Link to="/">
                  <Button disabled={carregando} variant="contained">
                    Voltar
                  </Button>
                </Link>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              {produtosInseridos.length > 0 && (
                <>
                  <Painel titulo="Pagamento" className={"m-3 p-0"}>
                    <Typography variant="subtitle1" className={"mb-3"}>
                      Selecione a forma de pagamento para prosseguir
                    </Typography>
                    {BotaoPagamento()}
                  </Painel>
                  <Painel titulo="Produtos inseridos" className={"m-3"}>
                    <List component="nav" className="p-0">
                      {produtosInseridos.map((produto) => {
                        return (
                          <ListItem key={produto.id} divider>
                            <ListItemIcon>
                              <AdicionaRemoveQuantidade
                                quantidade={produto.quantidade}
                                adicionaQuantidade={adicionaQuantidade}
                                removeQuantidade={removeQuantidade}
                                produto_id={produto.id}
                                className={"mr-3"}
                                removerProduto={removerProduto}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                produto.nome +
                                " - " +
                                produto.valor_tabela.toLocaleString("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                })
                              }
                              // secondary={produto.categoria}
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Painel>
                </>
              )}
            </Grid>
          </>
        )}
        {etapaAtual === 1 && (
          <>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <div className="m-3 mt-0">
                  <Typography variant="subtitle1" className={"mb-3"}>
                    Informe os dados do cliente.
                  </Typography>
                </div>
                <Painel titulo="Dados do cliente" className={"m-3"}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="Nome"
                        margin="dense"
                        autoComplete="off"
                        fullWidth
                        value={cliente.nome ? cliente.nome : ""}
                        onChange={(e) =>
                          atualizaDadosCliente("nome", e.target.value)
                        }
                        error={clienteErros.nome}
                        helperText={clienteErros.nome}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="Email"
                        margin="dense"
                        autoComplete="off"
                        fullWidth
                        value={cliente.email ? cliente.email : ""}
                        onChange={(e) =>
                          atualizaDadosCliente("email", e.target.value)
                        }
                        error={clienteErros.email}
                        helperText={clienteErros.email}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="DDD"
                        margin="dense"
                        autoComplete="off"
                        fullWidth
                        value={cliente.ddd ? cliente.ddd : ""}
                        onChange={(e) =>
                          atualizaDadosCliente("ddd", e.target.value)
                        }
                        error={clienteErros.ddd}
                        helperText={clienteErros.ddd}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="Telefone"
                        margin="dense"
                        autoComplete="off"
                        fullWidth
                        value={
                          cliente.telefone_sem_ddd
                            ? cliente.telefone_sem_ddd
                            : ""
                        }
                        onChange={(e) =>
                          atualizaDadosCliente(
                            "telefone_sem_ddd",
                            e.target.value
                          )
                        }
                        error={clienteErros.telefone_sem_ddd}
                        helperText={clienteErros.telefone_sem_ddd}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Genero
                        </InputLabel>
                        <Select
                          value={cliente.genero ? cliente.genero : ""}
                          label="Generor"
                          onChange={(e) =>
                            atualizaDadosCliente("genero", e.target.value)
                          }
                        >
                          <MenuItem value={"E"}>Empresa</MenuItem>
                          <MenuItem value={"F"}>Feminino</MenuItem>
                          <MenuItem value={"M"}>Masculino</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label={cliente.genero === "E" ? "CNPJ" : "CPF"}
                        margin="dense"
                        autoComplete="off"
                        fullWidth
                        value={cliente.documento ? cliente.documento : ""}
                        onChange={(e) =>
                          atualizaDadosCliente("documento", e.target.value)
                        }
                        error={clienteErros.documento}
                        helperText={clienteErros.documento}
                      />
                    </Grid>
                    {cliente.genero === "E" && (
                      <Grid item xs={12} md={6} lg={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Contribuinte Estadual
                          </InputLabel>
                          <Select
                            value={
                              cliente.contribuinte_estadual
                                ? cliente.contribuinte_estadual
                                : ""
                            }
                            label="contribuinte_estadualr"
                            onChange={(e) =>
                              atualizaDadosCliente(
                                "contribuinte_estadual",
                                e.target.value
                              )
                            }
                          >
                            <MenuItem value={"0"}>Não</MenuItem>
                            <MenuItem value={"1"}>Sim</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                    {cliente.genero === "E" && (
                      <Grid item xs={12} md={6} lg={4}>
                        <TextField
                          label="Inscricao Estadual"
                          margin="dense"
                          autoComplete="off"
                          fullWidth
                          value={
                            cliente.inscricao_estadual
                              ? cliente.inscricao_estadual
                              : ""
                          }
                          onChange={(e) =>
                            atualizaDadosCliente(
                              "inscricao_estadual",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                    )}
                    {cliente.genero === "E" && (
                      <Grid item xs={12} md={6} lg={4}>
                        <TextField
                          label="Inscricao Municipal"
                          margin="dense"
                          autoComplete="off"
                          fullWidth
                          value={
                            cliente.inscricao_municipal
                              ? cliente.inscricao_municipal
                              : ""
                          }
                          onChange={(e) =>
                            atualizaDadosCliente(
                              "inscricao_municipal",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                    )}
                  </Grid>
                </Painel>

                {/* Endereço */}
                <Painel titulo="Endereço do cliente" className={"m-3"}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="CEP"
                        margin="dense"
                        autoComplete="off"
                        fullWidth
                        value={cliente.cep ? cliente.cep : ""}
                        onChange={(e) =>
                          atualizaDadosCliente("cep", e.target.value)
                        }
                        error={clienteErros.cep}
                        helperText={clienteErros.cep}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="Endereço + Número"
                        margin="dense"
                        autoComplete="off"
                        fullWidth
                        value={cliente.endereco ? cliente.endereco : ""}
                        onChange={(e) =>
                          atualizaDadosCliente("endereco", e.target.value)
                        }
                        error={clienteErros.endereco}
                        helperText={clienteErros.endereco}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="Complemento"
                        margin="dense"
                        autoComplete="off"
                        fullWidth
                        value={cliente.complemento ? cliente.complemento : ""}
                        onChange={(e) =>
                          atualizaDadosCliente("complemento", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="Bairro"
                        margin="dense"
                        autoComplete="off"
                        fullWidth
                        value={cliente.bairro ? cliente.bairro : ""}
                        onChange={(e) =>
                          atualizaDadosCliente("bairro", e.target.value)
                        }
                        error={clienteErros.bairro}
                        helperText={clienteErros.bairro}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="Cidade"
                        margin="dense"
                        autoComplete="off"
                        fullWidth
                        value={cliente.municipio ? cliente.municipio : ""}
                        onChange={(e) =>
                          atualizaDadosCliente("municipio", e.target.value)
                        }
                        error={clienteErros.municipio}
                        helperText={clienteErros.municipio}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="Estado"
                        margin="dense"
                        autoComplete="off"
                        fullWidth
                        value={cliente.estado ? cliente.estado : ""}
                        inputProps={{
                          maxLength: 2,
                        }}
                        onChange={(e) =>
                          atualizaDadosCliente("estado", e.target.value)
                        }
                        error={clienteErros.estado}
                        helperText={clienteErros.estado}
                      />
                    </Grid>
                  </Grid>
                </Painel>
                {/* Endereço entrega */}
                <Painel titulo="Endereço de entrega" className={"m-3"}>
                  <Button
                    onClick={() => copiarEndereco()}
                    size="small"
                    variant="outlined"
                  >
                    Copiar do endereço
                  </Button>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="CEP"
                        margin="dense"
                        autoComplete="off"
                        fullWidth
                        value={cliente.cep_entrega ? cliente.cep_entrega : ""}
                        onChange={(e) =>
                          atualizaDadosCliente("cep_entrega", e.target.value)
                        }
                        error={clienteErros.estado}
                        helperText={clienteErros.estado}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="Endereço + Número"
                        margin="dense"
                        autoComplete="off"
                        fullWidth
                        value={
                          cliente.endereco_entrega
                            ? cliente.endereco_entrega
                            : ""
                        }
                        onChange={(e) =>
                          atualizaDadosCliente(
                            "endereco_entrega",
                            e.target.value
                          )
                        }
                        error={clienteErros.endereco_entrega}
                        helperText={clienteErros.endereco_entrega}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="Complemento"
                        margin="dense"
                        autoComplete="off"
                        fullWidth
                        value={
                          cliente.complemento_entrega
                            ? cliente.complemento_entrega
                            : ""
                        }
                        onChange={(e) =>
                          atualizaDadosCliente(
                            "complemento_entrega",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="Bairro"
                        margin="dense"
                        autoComplete="off"
                        fullWidth
                        value={
                          cliente.bairro_entrega ? cliente.bairro_entrega : ""
                        }
                        onChange={(e) =>
                          atualizaDadosCliente("bairro_entrega", e.target.value)
                        }
                        error={clienteErros.bairro_entrega}
                        helperText={clienteErros.bairro_entrega}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="Cidade"
                        margin="dense"
                        autoComplete="off"
                        fullWidth
                        value={
                          cliente.municipio_entrega
                            ? cliente.municipio_entrega
                            : ""
                        }
                        onChange={(e) =>
                          atualizaDadosCliente(
                            "municipio_entrega",
                            e.target.value
                          )
                        }
                        error={clienteErros.municipio_entrega}
                        helperText={clienteErros.municipio_entrega}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="Estado"
                        margin="dense"
                        autoComplete="off"
                        fullWidth
                        value={
                          cliente.estado_entrega ? cliente.estado_entrega : ""
                        }
                        inputProps={{
                          maxLength: 2,
                        }}
                        onChange={(e) =>
                          atualizaDadosCliente("estado_entrega", e.target.value)
                        }
                        error={clienteErros.estado_entrega}
                        helperText={clienteErros.estado_entrega}
                      />
                    </Grid>
                  </Grid>
                </Painel>
                <div className="d-flex m-3 mt-6 j-between">
                  <div>
                    <Button
                      disabled={carregando}
                      variant="contained"
                      onClick={() => voltarParaProdutos()}
                    >
                      Voltar
                    </Button>
                  </div>
                  <div>
                    <Button
                      disabled={carregando}
                      variant="contained"
                      color="primary"
                      onClick={() => salvarDadosCliente()}
                    >
                      {carregando ? "Aguarde..." : "Avançar"}
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </>
        )}
        {etapaAtual === 2 && cliente && (
          <>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Painel titulo="Resumo" className={"m-3"}>
                  <Typography variant="h6">Produtos</Typography>
                  {produtosInseridos.map((produto) => {
                    return (
                      <Typography key={produto.nome}>
                        {produto.quantidade}x {produto.nome}
                      </Typography>
                    );
                  })}
                  <Typography variant="h6" className="mt-4">
                    Entrega
                  </Typography>
                  {cliente.endereco_entrega}
                  {cliente.complemento_entrega !== ""
                    ? " - complemento: " + cliente.complemento_entrega
                    : ""}{" "}
                  - {cliente.bairro_entrega} - {cliente.cidade_entrega}{" "}
                  {cliente.estado_entrega} - CEP: {cliente.cep_entrega}
                  <Typography variant="h6" className="mt-4">
                    Valor
                  </Typography>
                  {pagamento.valor_com_desconto.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}{" "}
                  {pagamento.nome}
                  <Typography variant="h6" className="mt-4">
                    Pagamento
                  </Typography>
                  <TextField
                    label="Link do pagamento"
                    variant="filled"
                    margin="dense"
                    autoComplete="off"
                    fullWidth
                    value={urlPagamento}
                    helperText={"Envie este link para o cliente"}
                  />
                  {}
                </Painel>
                <div className="d-flex m-3 mt-6 j-between">
                  <div>
                    <Link to="/">
                      <Button className="mt-3" variant="contained">
                        Voltar para o início
                      </Button>
                    </Link>
                  </div>
                  <div>
                    {/* <Button
                      disabled={carregando || urlPagamento === ""}
                      variant="contained"
                      color="primary"
                      onClick={() => abrirPagamento()}
                    >
                      {carregando ? "Aguarde..." : "Realizar o pagamento"}
                    </Button> */}
                  </div>
                </div>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default PedidoCriar;
