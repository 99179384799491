import { createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import storage from "redux-persist/lib/storage";
import rootReducer from "../reducers";
const persistConfig = {
  key: "root",
  storage: storage,
  stateReconciler: hardSet,
};

const pReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(pReducer);
export const persistor = persistStore(store);
