import Axios from "axios";

Axios.defaults.headers.post["Content-Type"] = "application/json";

// Axios.defaults.baseURL = "http://admin.metalife.local";
Axios.defaults.baseURL = "https://admin.metalife.com.br";

Axios.interceptors.request.use(
  (request) => {
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
