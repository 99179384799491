import { Button, Card, Grid, TextField, Typography } from "@material-ui/core";
import Axios from "axios";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Painel from "../componentes/painel/Painel";
import AuthContext from "../context/auth-context";

const Login = (props) => {
  const authContext = useContext(AuthContext);
  const [dadosFormulario, setDadosFormulario] = useState({
    email: "",
    password: "",
  });
  const [errosForm, setErrosFormulario] = useState();
  const [carregando, setCarregando] = useState(false);

  const logar = async () => {
    if (carregando) return;
    setCarregando(true);

    Axios.post("/api/venda-direta/usuario/logar", dadosFormulario)
      .then((response) => {
        return response.data.dados;
      })
      .then((response) => {
        const dadosLoginOk = {
          id: response.id,
          nome: response.nome,
          token: response.token,
          grupo_nome: response.grupo_nome,
        };
        authContext.login(dadosLoginOk);
      })
      .catch((error) => {
        alert("Usurário ou senha inválidos");
        setCarregando(false);
      });
  };

  return (
    <div className={"align-items-center h-100 j-center d-flex"}>
      <Grid container spacing={0} justifyContent="center">
        <Grid item xs={11} md={4} lg={3}>
          <Painel titulo="Entrar" className={"mt-3"}>
            <TextField
              margin="dense"
              id="email"
              name="email"
              label="E-mail"
              autoComplete="off"
              fullWidth
              autoFocus
              error={errosForm && errosForm.email && errosForm.email.length > 0}
              helperText={
                errosForm &&
                errosForm.email &&
                errosForm.email.length > 0 &&
                errosForm.email[0]
              }
              value={
                dadosFormulario && dadosFormulario.email
                  ? dadosFormulario.email
                  : ""
              }
              onChange={(e) =>
                setDadosFormulario({
                  ...dadosFormulario,
                  email: e.target.value,
                })
              }
              className={"mb-3"}
            />
            <TextField
              margin="dense"
              id="password"
              name="password"
              label="Senha"
              autoComplete="off"
              type="password"
              fullWidth
              error={
                errosForm && errosForm.password && errosForm.password.length > 0
              }
              helperText={
                errosForm &&
                errosForm.password &&
                errosForm.password.length > 0 &&
                errosForm.password[0]
              }
              value={
                dadosFormulario && dadosFormulario.password
                  ? dadosFormulario.password
                  : ""
              }
              onChange={(e) =>
                setDadosFormulario({
                  ...dadosFormulario,
                  password: e.target.value,
                })
              }
              className={"mb-3"}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={logar}
              disabled={carregando}
              className="mt-1"
            >
              {carregando ? "Aguarde..." : "Entrar"}
            </Button>
          </Painel>
          <Card className={"mt-3 p-3 text-center"}>
            <Typography variant="body2" className="mt-3">
              Não tem dados de acesso?
              <br />
              Clique no botão abaixo.
            </Typography>
            <Link to="/solicitar-acesso">
              <Button variant="contained" color="default" className="mt-3">
                Solicitar acesso
              </Button>
            </Link>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;
