import { Card, CardContent } from "@material-ui/core";
import React from "react";
import PainelTopo from "./PainelTopo";

const Painel = (props) => {
    return (
        <>
            <Card className={props.className ? props.className : ""}>
                <PainelTopo
                    titulo={props.titulo}
                    fechar={props.fechar ? props.fechar : null}
                />
                <CardContent>{props.children}</CardContent>
            </Card>
        </>
    );
};

export default Painel;
